import Box from './Box'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components'
import { Link } from 'gatsby'

const TypographyStyled = styled(Box)``

const Typography = React.forwardRef(
  ({ children, variant = 'p', slugger, ...props }, ref) => {
    const theme = useContext(ThemeContext)
    const variantProps = theme.typography[variant]
    const scale = props.scale || variantProps.scale
    const tag = props.tag || variantProps.tag

    return (
      <TypographyStyled
        as={tag}
        fontSize={{
          xs: theme.typography.scaleToFontSizeMobile(scale),
          md: theme.typography.scaleToFontSize(scale),
        }}
        {...variantProps}
        {...props}
        ref={ref}
      >
        {children}
      </TypographyStyled>
    )
  }
)

export default Typography
