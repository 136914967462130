import styled from 'styled-components'
import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  breakpoints,
  compose,
  css,
} from '@material-ui/system'

const Box = styled('div')`
  ${breakpoints(
    css(
      compose(
        borders,
        display,
        flexbox,
        palette,
        positions,
        shadows,
        sizing,
        spacing,
        typography
      )
    )
  )}
`

export default Box
