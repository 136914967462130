/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import queryString from 'query-string'
import { mixpanel } from 'gatsby-plugin-mixpanel'

//Nicked from https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-remark-autolink-headers/src/gatsby-browser.js
// If we don't have this, then the browser won't scroll to the element

let offsetY = 0

const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      return (
        element.getBoundingClientRect().top + scrollTop - clientTop - offsetY
      )
    }
  }
  return null
}

export const onInitialClientRender = (_, pluginOptions) => {
  if (pluginOptions.offsetY) {
    offsetY = pluginOptions.offsetY
  }

  requestAnimationFrame(() => {
    const offset = getTargetOffset(window.location.hash)
    if (offset !== null) {
      window.scrollTo(0, offset)
    }
  })
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const offset = getTargetOffset(location.hash)
  return offset !== null ? [0, offset] : true
}

export const onRouteUpdate = ({ location }) => {
  // Don't track while developing.
  if (
    process.env.NODE_ENV === `production` &&
    typeof window.fbq === `function`
  ) {
    window.fbq('track', 'ViewContent')
  }

  const search = queryString.parse(location.search)
  // Just modify the keys, returning as an array.
  // Then just assign this to a new object.
  // See: https://stackoverflow.com/a/43839611/1772238
  const mixpanelSearch = Object.assign(
    {},
    ...Object.keys(search).map(k => {
      return { ['Search Key ' + k]: search[k] }
    })
  )
  mixpanel.track('Page View', { url: location.pathname, ...mixpanelSearch })
}
