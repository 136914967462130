/**
 * Index component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import theme from 'src/theme'
import Helmet from 'react-helmet'

const Inner = ({ children }) => {
  const themeCtx = useContext(ThemeContext)
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          html {
              font-size: 62.5%;
              color: ${themeCtx.palette.text['0']};
              font-family: ${themeCtx.typography.bodyFontFamily};
          }
          body {
            font-size: ${themeCtx.typography.baseFontSize / 10.0}rem;
          }
          * {
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          b, strong {
            font-weight: 700 !important;
          }
          a {
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
            `}</style>
        <link rel="stylesheet" href="https://use.typekit.net/ibc7lhp.css" />
      </Helmet>
      {children}
    </>
  )
}

const Layout = ({ children }) => {
  return (
    <>
      <Normalize />
      <ThemeProvider theme={theme}>
        <Inner>{children}</Inner>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
