import React from 'react'
import ms from 'modularscale-js'
import Typography from '../components/System/Typography'

const variants = [
  {
    variant: 'body',
    tag: 'p',
    scale: 1,
    lineHeight: 1.5,
    fontWeight: '200',
    mb: '1em',
  },
  {
    variant: 'body1',
    tag: 'p',
    scale: 1,
    lineHeight: 1.5,
    fontWeight: '200',
  },
  { variant: 'p', tag: 'p', scale: 0, lineHeight: 1.25, fontWeight: '200' },
  {
    variant: 'subnote',
    tag: 'p',
    scale: -2,
    lineHeight: 1.25,
    fontWeight: '400',
  },
  { variant: 'note', tag: 'p', scale: 0, lineHeight: 1.25, fontWeight: '400' },
  {
    variant: 'h6',
    tag: 'h6',
    scale: 1,
    lineHeight: 1.25,
    fontWeight: '600',
    color: '#444',
  },
  {
    variant: 'h5',
    tag: 'h5',
    scale: 2,
    lineHeight: 1.25,
    fontWeight: '600',
    color: '#444',
  },
  { variant: 'h4', tag: 'h4', scale: 3, lineHeight: 1.25, fontWeight: '600' },
  { variant: 'h3', tag: 'h3', scale: 4, lineHeight: 1.25, fontWeight: '600' },
  { variant: 'h2', tag: 'h2', scale: 5, lineHeight: 1.25, fontWeight: '600' },
  {
    variant: 'subtitle',
    tag: 'p',
    scale: 5,
    lineHeight: 1.25,
    fontWeight: '200',
  },
  { variant: 'h1', tag: 'h1', scale: 6, lineHeight: 1.25, fontWeight: '600' },
  {
    variant: 'button',
    tag: 'button',
    scale: 2,
    lineHeight: 1.25,
    mb: 0,
    fontWeight: '600',
  },
  {
    variant: 'button-sm',
    tag: 'button',
    scale: 0,
    lineHeight: 1.25,
    mb: 0,
    fontWeight: '600',
  },
]

const defaultOpts = {
  baseFontSize: 20,
  baseFontSizeMobile: 18,
  fontFamily: 'Helvetica, sans-serif',
  ratio: 1.067,
  ratioMobile: 1.067,
}

const createTypographyTheme = userOpts => {
  const opts = { ...defaultOpts, ...userOpts }
  const {
    baseFontSize,
    baseFontSizeMobile,
    ratio,
    ratioMobile,
    headerFontFamily,
    bodyFontFamily,
  } = opts

  const scaleToFontSize = scale =>
    ms(scale, { base: baseFontSize / 10.0, ratio }) + 'rem'
  const scaleToFontSizeMobile = scale =>
    ms(scale, { base: baseFontSizeMobile / 10.0, ratio: ratioMobile }) + 'rem'

  const typographyTheme = { ...opts, scaleToFontSize, scaleToFontSizeMobile }
  variants.forEach(v => {
    const fontFamily =
      v.variant === 'body' || v.variant === 'p'
        ? bodyFontFamily
        : headerFontFamily
    typographyTheme[v.variant] = {
      m: 0,
      mb: '0em',
      ...v,
      fontFamily,
      component: ({ children, ...props }) => (
        <Typography variant={v.variant} {...props}>
          {children}
        </Typography>
      ),
    }
  })
  return typographyTheme
}

export { createTypographyTheme }
