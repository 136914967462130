// For default theme, see: https://material-ui.com/customization/default-theme
import { createTypographyTheme } from '@local/shared/theme'

const values = {
  xs: 0,
  vs: 400,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const theme = {
  spacing: 4,
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    keys: ['xs', 'vs', 'sm', 'md', 'lg', 'xl'],
    up: key => `@media (min-width:${values[key]}px)`,
  },
  palette: {
    text: {
      0: '#222222',
      placeholder: '#7f7f7f',
      blockquote: '#444',
    },
    green: {
      0: '#5FCE7F',
      light0: '#96CCA4',
    },
    blue: {
      dark0: '#0C4B77',
      0: '#9CCFF0',
      light0: '#B2DBED',
      light1: '#D0E4EF',
      light2: '#E2E9ED',
    },
    yellow: {
      dark1: '#EDD929',
      dark0: '#efe241',
      0: '#F0EC82',
      light0: '#f7efa6',
      light1: '#fffbd0',
    },
  },
  typography: createTypographyTheme({
    headerFontFamily: 'altivo, sans-serif',
    bodyFontFamily: 'zenon, sans-serif',
  }),
}

export default theme
