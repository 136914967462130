// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-free-fasting-protocol-js": () => import("./../src/pages/free-fasting-protocol.js" /* webpackChunkName: "component---src-pages-free-fasting-protocol-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-after-pregnancy-js": () => import("./../src/pages/lp/after-pregnancy.js" /* webpackChunkName: "component---src-pages-lp-after-pregnancy-js" */),
  "component---src-pages-lp-before-pregnancy-js": () => import("./../src/pages/lp/before-pregnancy.js" /* webpackChunkName: "component---src-pages-lp-before-pregnancy-js" */),
  "component---src-pages-lp-general-js": () => import("./../src/pages/lp/general.js" /* webpackChunkName: "component---src-pages-lp-general-js" */),
  "component---src-pages-lp-holiday-js": () => import("./../src/pages/lp/holiday.js" /* webpackChunkName: "component---src-pages-lp-holiday-js" */),
  "component---src-pages-lp-hvco-free-weight-loss-programme-js": () => import("./../src/pages/lp/hvco/free-weight-loss-programme.js" /* webpackChunkName: "component---src-pages-lp-hvco-free-weight-loss-programme-js" */),
  "component---src-pages-lp-hvco-general-js": () => import("./../src/pages/lp/hvco/general.js" /* webpackChunkName: "component---src-pages-lp-hvco-general-js" */),
  "component---src-pages-lp-pre-surgery-js": () => import("./../src/pages/lp/pre-surgery.js" /* webpackChunkName: "component---src-pages-lp-pre-surgery-js" */),
  "component---src-pages-lp-survey-survey-js": () => import("./../src/pages/lp/survey/survey.js" /* webpackChunkName: "component---src-pages-lp-survey-survey-js" */),
  "component---src-pages-lp-v-2-free-consultation-js": () => import("./../src/pages/lp/v2/free-consultation.js" /* webpackChunkName: "component---src-pages-lp-v-2-free-consultation-js" */),
  "component---src-pages-lp-wedding-js": () => import("./../src/pages/lp/wedding.js" /* webpackChunkName: "component---src-pages-lp-wedding-js" */),
  "component---src-pages-thank-you-free-consultation-js": () => import("./../src/pages/thank-you/free-consultation.js" /* webpackChunkName: "component---src-pages-thank-you-free-consultation-js" */),
  "component---src-pages-thank-you-hvco-js": () => import("./../src/pages/thank-you/hvco.js" /* webpackChunkName: "component---src-pages-thank-you-hvco-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-subscribe-js": () => import("./../src/pages/thank-you/subscribe.js" /* webpackChunkName: "component---src-pages-thank-you-subscribe-js" */),
  "component---src-pages-thank-you-survey-js": () => import("./../src/pages/thank-you/survey.js" /* webpackChunkName: "component---src-pages-thank-you-survey-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-guide-js": () => import("./../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */)
}

